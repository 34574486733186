/* header css */

#header {
  padding: 15px 0;
  position:fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 9999;
  background: #fff;
  box-shadow: 1px 1px 50px 1px rgba(38, 231, 9, 0.1);
  transition: all 0.5s linear;
}

#header .navbar {
  padding: 0;
  justify-content: space-between;
  background:  none !important;
}

#header .navbar-brand {
  color: #038D4C;
  text-transform: uppercase;
  font-weight: 400;
  height: auto;
  padding: 0;
  font-size: 22px;
  line-height: 1.6;
  font-family: "Kalnia", serif;
}

#header .navbar-collapse {
  flex-grow: 0;
}

#header .navbar-collapse .navbar-nav {
  font-size: 17px;
  line-height: 20px;
  
}

#header .navbar-collapse a:last-child {
  padding-right: 0;
}

#header .navbar-collapse a {
  color: #0c0c0b;
  position: relative;
}

#header .navbar-collapse a:hover,
#header .navbar-collapse a.active {
  background: none;
  color:  #038D4C;
}

#header .navbar-collapse a:hover:before,
#header .navbar-collapse a.active:before {
  right: 7px;
  left: 8px;
  opacity: 1;
}

#header .navbar-collapse a:before {
  content: '';
  background: #038D4C;
  height: 1px;
  position: absolute;
  bottom: 7px;
  left: -20px;
  right: 100%;
  transition: all 0.3s linear;
  -webkit-transition: all 0.3s linear;
  opacity: 0;
}


.btn.btn-primary {
  background:  #3ab714;
  border-color:  #3ab714;
  padding: 13px 35px;
  font-size: 14px;
  line-height: 18px;
  font-weight: 600;
  text-transform: uppercase;
  transition: background 0.3s linear, color 0.3s linear;
  -webkit-transition: background 0.3s linear, color 0.3s linear;
}

.btn.btn-primary:focus,
.btn.btn-primary:hover {
  background: #fff;
  border-color:  #3ab714;
  color:  #3ab714;
}

.btn.btn-primary .fas {
  margin: 0 0 0 6px;
  font-size: 15px;
}



/* home css */
.home-block {
  margin: 65px 0 0;
}

.home-block .custom-image {
  width: 100%;
  height:auto;
  max-height: 560px; 
  object-fit:fill;
}

.title-holder {
  text-align: center;
  margin-top: 15px;
}

.title-holder h2 {
  margin: 0 0 5px;
  text-transform: uppercase;
  font-size: 40px; 
  font-family: "Kalnia", serif;
  font-style:normal;
  font-weight: 400;
  font-variation-settings:
    "wdth" 100;
  color: #038D4C;
  animation: fadeIn 1s ease-out forwards;
}
/* About block css */
.about-block .container-fluid{
  padding-top: 50px;
  text-align: justify;
}

.aboutcontent {
  max-width: 90%; 
  margin: 20px auto; 
  animation: slideIn 1s ease-out forwards, fadeIn 1s ease-out forwards; 
}

.aboutcontent p {
  font-size: 20px; 
  color: #0c0c0b; 
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    transform: translateY(50px);
  }
  to {
    transform: translateY(0);
  }
}

.Missiontitle{
   padding-top: 25px;
   max-width: 90%;
   margin: 20px auto;
}

.Missiontitle h2{
  padding-bottom:3px;
  font-size: 35px; 
  font-weight: 400;
  font-family: "Kalnia", serif;
  color: #038D4C;  
}

.Missiontitle p{
  font-size: 20px; /* Adjust the font size of the paragraph */
  color: #0c0c0b;
}

.Visiontitle{
  padding-top: 25px;
  max-width: 90%;
  margin: 20px auto;
}

.Visiontitle h2{
 padding-bottom:3px;
 font-size: 35px; 
 font-weight: 400;
 font-family: "Kalnia", serif;
 color: #038D4C;
}

.Visiontitle p{
 font-size: 20px; 
 color: #0c0c0b;
}

.product-block {
  padding-top: 50px;
  background: linear-gradient(to bottom, #ffffff, #b9ddb4);
  position:relative;
  color: #0c0c0b; 
}

.product-block:before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
} 

 .product-block .container-fluid {
  position: relative;
  z-index: 1;
  margin: 0 auto;
}

.product-block h3 {
  padding-top:20px;
  text-align: center;
  font-size: 30px;
  text-transform: uppercase;
  font-weight: 400;
  font-family: "Kalnia", serif;
  color: #038D4C;
  padding-bottom: 20px;
}

.product-block p {
  padding-top: 10px;
  max-width:90%;
  text-align: justify;
  color: #0c0c0b;
  font-size: 20px;
}

.my-carousel {
  height: 400px; 
}

.carousel-content {
  height: 100%; 
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.product-block .carousel-indicators {
  position: absolute;
  bottom: 10px; 
  left: 35%;
  transform: translateX(-50%);
}

.product-block .carousel-indicators button {
  border:0;
}

.product-block .carousel-indicators .active {
 background: #038D4C;
}

/* Our Team css */
.teams-block .container-fluid{
  max-width: 900px;
  margin-bottom: 20px;
  padding-top:50px;
}

.teams-block .title-holder{
  padding-top:20px;
  padding-bottom: 20px;
}

.teams-block .content h3{
  text-align: center;
}

.teams-block .content .designation{
  text-align:center;
}
.teams-block .row:last-child {
  margin: 0 -15px;
}

.teams-block [class*="col-"]:hover .overlay {
  top: 0;
  opacity: 1;
}

.teams-block .image {
  margin: 0 0 20px;
  position: relative;
  overflow: hidden;
}

.teams-block .image img {
  width: 100%;
  height: auto;
}

.teams-block .image .overlay {
  position: absolute;
  top: 50%;
  bottom: 0;
  left: 0;
  right: 0;
  transition: all 0.3s linear;
  -webkit-transition: all 0.2s linear;
  vertical-align: middle;
  background: rgba(168, 245, 135, 0.9);
  opacity: 0;
}

.teams-block h3 {
  margin: 0 0 5px;
}

.teams-block .designation {
  display: block;
  font-style: italic;
  margin: 0 0 20px;
}


.socials {
  position: absolute;
  top: 45%;
  left: 43%;
  transform: translate(-50%, -50%);
}

.socials ul {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: 0;
}

.socials li {
  display: inline-block;
  vertical-align: middle;
  padding: 0 5px;
}

.socials a {
  width: 35px;
  height: 35px;
  background: #fff;
  color: rgba(168, 245, 135, 0.9);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 2px;
  transition: background 0.3s linear, color 0.3s linear;
  -webkit-transition: background 0.3s linear, color 0.3s linear;
}


.socials a:hover {
  background:  rgba(168, 245, 135, 0.9);
  color: #fff;
}

.socials .fab {
  line-height: 2;
}

.contact-block .container-fluid{
  /* max-width: 90%; */
  margin-bottom: 30px;
  padding-top:50px;
}

.contact-block .title-holder{
  margin-top:35px;
  padding-bottom:30px;
  text-align: center;
}
.contact-div{
  max-width: 80%;
  margin:20px auto;
}
.google-map-container {
  position: relative;
  width: 100%;
  height:100%;
  padding-bottom: 50%; 
}

.google-map {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.contact-info{
  position: relative;
  display:flex;
  flex-direction: column;
  padding-left: 30px;
  padding-top:40px;
}

.contact-block .contact-info .contact-info-item1 p{
  font-size:18px;
  color: #0c0c0b;
  margin-left: 20px;
  padding-top:10px;
}

.contact-info-item1 {
  display: flex;
  align-items: center;
  padding-bottom: 10px; 
}

.contact-block .contact-info .contact-info-item2 p{
  font-size:18px;
  color: #0c0c0b;
  margin-left: 25px; 
}
.contact-block .contact-info .contact-info-item2 i{
  padding-top:5px; 
}
.contact-info-item2 {
  display: flex;
  margin-bottom: 10px; 
  align-items:flex-start;
}

.contact-block .contact-info .contact-info-item3 p{
  font-size:18px;
  color: #0c0c0b;
  margin-left: 20px;
  
}
.contact-info-item3 {
  display: flex;
  margin-bottom: 10px; 
  align-items:flex-start;
}
#footer {
  background:rgba(203, 249, 183, 0.9);
  padding-top:40px;
  padding-bottom:40px;
  color:  #0c0c0b;
  font-size: 18px;
  text-align: center;
}

#footer .copyright {
  margin: 0 0 15px;
}

#footer .socialsicon a:hover {
  background: rgba(168, 245, 135, 0.9);
}

.socialsicon {
  position: relative;
  padding-top: 40px;
  left: 50%;
  transform: translate(-50%, -50%);
}

.socialsicon ul {
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.socialsicon li {
  margin: 0 5px;
}

.socialsicon a {
  width: 40px;
  height: 40px;
  background: #fff;
  color: #158d03;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 5px;
  transition: background 0.3s linear, color 0.3s linear;
}

.socialsicon a:hover {
  background: #158d03; 
  color: #fff; 
}
@media screen and (min-device-width: 320px) 
    and (max-device-width: 480px){
      
      .product-block h3 {
        padding-top:20px;
        text-align: center;
        font-size: 25px;
        text-transform: uppercase;
        font-weight: 400;
        font-family: "Kalnia", serif;
        color: #038D4C;
        padding-bottom: 20px;
      }
      
      .product-block p {
        padding-top: 7px;
        max-width:95%;
        text-align: justify;
        color: #0c0c0b;
        font-size: 15px;
      }
      
      .my-carousel {
        height: 560px; 
      }
      .title-holder h2 {
        margin: 0 0 5px;
        text-transform: uppercase;
        font-size: 33px; 
        font-family: "Kalnia", serif;
        font-style:normal;
        font-weight: 400;
        font-variation-settings:
          "wdth" 100;
        color: #038D4C;
        animation: fadeIn 1s ease-out forwards;
      }

      .aboutcontent p {
        font-size: 16px; 
        color: #0c0c0b; 
      }

      .Missiontitle h2{
        padding-bottom:3px;
        font-size: 31px; 
        font-weight: 400;
        font-family: "Kalnia", serif;
        color: #038D4C;
        
      }
      
      .Missiontitle p{
        font-size: 16px; 
        color: #0c0c0b;
      }
      .Visiontitle h2{
        padding-bottom:3px;
        font-size: 31px; 
        font-weight: 400;
        font-family: "Kalnia", serif;
        color: #038D4C;
       }
       
       .Visiontitle p{
        font-size: 16px; 
        color: #0c0c0b;
       }
       .contact-block .contact-info .contact-info-item1 p{
        font-size:16px;
        color: #0c0c0b;
        margin-left: 20px;
        padding-top:10px;
      }
      
      .contact-info-item1 {
        display: flex;
        align-items: center;
        padding-bottom: 10px; 
      }
      
      .contact-block .contact-info .contact-info-item2 p{
        font-size:16px;
        color: #0c0c0b;
        margin-left: 25px; 
      }
      .contact-block .contact-info .contact-info-item2 i{
        padding-top:5px; 
      }
      .contact-info-item2 {
        display: flex;
        margin-bottom: 10px; 
        align-items:flex-start;
      }
      
      .contact-block .contact-info .contact-info-item3 p{
        font-size:16px;
        color: #0c0c0b;
        margin-left: 20px;
        
      }
      .contact-info-item3 {
        display: flex;
        margin-bottom: 10px; 
        align-items:flex-start;
      }
      #footer {
        background:rgba(203, 249, 183, 0.9);
        padding-top:40px;
        padding-bottom:40px;
        color:  #0c0c0b;
        font-size: 16px;
        text-align: center;
      }
}

@media screen and (min-device-width: 481px) 
    and (max-device-width: 768px){
      .product-block h3 {
        padding-top:20px;
        text-align: center;
        font-size: 25px;
        text-transform: uppercase;
        font-weight: 400;
        font-family: "Kalnia", serif;
        color: #038D4C;
        padding-bottom: 20px;
      }
      
      .product-block p {
        padding-top: 7px;
        max-width:95%;
        text-align: justify;
        color: #0c0c0b;
        font-size: 17px;
      }
      
      .my-carousel {
        height: 520px; 
      }
      .title-holder h2 {
        margin: 0 0 5px;
        text-transform: uppercase;
        font-size: 35px; 
        font-family: "Kalnia", serif;
        font-style:normal;
        font-weight: 400;
        font-variation-settings:
          "wdth" 100;
        color: #038D4C;
        animation: fadeIn 1s ease-out forwards;
      }

      .aboutcontent p {
        font-size: 18px; 
        color: #0c0c0b; 
      }

      .Missiontitle h2{
        padding-bottom:3px;
        font-size: 33px; 
        font-weight: 400;
        font-family: "Kalnia", serif;
        color: #038D4C;
        
      }
      
      .Missiontitle p{
        font-size: 18px; 
        color: #0c0c0b;
      }
      .Visiontitle h2{
        padding-bottom:3px;
        font-size: 33px; 
        font-weight: 400;
        font-family: "Kalnia", serif;
        color: #038D4C;
       }
       
       .Visiontitle p{
        font-size: 18px; 
        color: #0c0c0b;
       }
       .contact-block .contact-info .contact-info-item1 p{
        font-size:18px;
        color: #0c0c0b;
        margin-left: 20px;
        padding-top:10px;
      }
      
      .contact-info-item1 {
        display: flex;
        align-items: center;
        padding-bottom: 10px; 
      }
      
      .contact-block .contact-info .contact-info-item2 p{
        font-size:18px;
        color: #0c0c0b;
        margin-left: 25px; 
      }
      .contact-block .contact-info .contact-info-item2 i{
        padding-top:5px; 
      }
      .contact-info-item2 {
        display: flex;
        margin-bottom: 10px; 
        align-items:flex-start;
      }
      
      .contact-block .contact-info .contact-info-item3 p{
        font-size:18px;
        color: #0c0c0b;
        margin-left: 20px;
        
      }
      .contact-info-item3 {
        display: flex;
        margin-bottom: 10px; 
        align-items:flex-start;
      }
      #footer {
        background:rgba(203, 249, 183, 0.9);
        padding-top:40px;
        padding-bottom:40px;
        color:  #0c0c0b;
        font-size: 18px;
        text-align: center;
      }
    }

    @media screen and (min-device-width: 768px) 
    and (max-device-width: 1024px){
      .product-block h3 {
        padding-top:20px;
        text-align: center;
        font-size: 30px;
        text-transform: uppercase;
        font-weight: 400;
        font-family: "Kalnia", serif;
        color: #038D4C;
        padding-bottom: 20px;
      }
      
      .product-block p {
        padding-top: 7px;
        max-width:95%;
        text-align: justify;
        color: #0c0c0b;
        font-size: 18px;
      }
      
      .my-carousel {
        height: 450px; 
      }
    }